










































































































import { computed, ref, Ref } from '@vue/composition-api';
import { defer } from 'rxjs';
import { retry } from 'rxjs/operators';

import { useUserActions, useStripeActions, useUserGetters } from '@/store';
import Profile from '@/components/Profile.vue';
import state from '@/store/modules/stripe/state';

export default {
  name: 'Payment',

  components: {
    Profile
  },

  setup(_, ctx: any) {
    const { getUser } = useUserGetters(['getUser']);
    const user: any = computed({
      get: () => getUser.value,
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const permissions = computed({
      get: () => localStorage?.getItem('permissions')?.split(',') || [],
      set: newVal => {
        ctx.emit('input', newVal);
      }
    });
    const organizationPermissions: any = ref(
      localStorage?.getItem('OrganizationPermissions')?.split(',') || []
    );
    const sponsorName = ref('');
    const { getProductInfos } = useUserActions(['getProductInfos']);
    const isOrg = ref(ctx.root.$route.query.organization);
    const qt = ref(100);

    const ColorCode = {
      'Per Student': 'green',
      'Per Family': 'orange',
      'Per Group': 'purple',
      'Per Class': 'blue',
      'Per Teacher': 'pink'
    };
    const purchaseOptions: Ref<
      {
        title: string;
        desc: string | null;
        priceId: string;
        price: number | null;
        color: any;
        quantity: number;
        unitLabel: string | null;
      }[]
    > = ref([]);
    getProductInfos().then(result => {
      purchaseOptions.value = result.map(res => ({
        title: res.product.name,
        desc: res.product.description,
        priceId: res.price.id,
        price: res.price.unit_amount,
        color: ColorCode[res.product.name],
        quantity: 0,
        unitLabel: res.product.unit_label
      }));
    });

    const purchaseOptionList = computed(() => {
      if (isOrg.value) return purchaseOptions.value.filter(p => p.title === 'Per Sponsorship');
      return purchaseOptions.value.filter(p => p.title !== 'Per Sponsorship');
    });

    const selectedProduct = computed(() => purchaseOptions.value.filter(opt => opt.quantity > 0));
    const { createCheckoutSession, createInvoice } = useUserActions([
      'createCheckoutSession',
      'createInvoice'
    ]);
    const invoiceState: any = ref({
      state: '',
      message: ''
    });
    const isCheckout = ref(false);
    const checkout = async () => {
      isCheckout.value = true;
      const payload: any = {
        lineItems: selectedProduct.value.map(item => ({
          price: item.priceId,
          quantity: item.quantity
        })),
        cancelUrl: 'http://pilotcity.com/sponsor',
        customerId: user.value.stripeId
      };
      if (ctx.root.$route.query.orgId) {
        payload.metadata = {
          organization_id: ctx.root.$route.query.orgId
        };
      }
      defer(() =>
        createCheckoutSession(payload)
          .then(async response => {
            setTimeout(() => {
              isCheckout.value = false;
            }, 2000);
            if (response.status === 400 || !response.id) return 'something went wrong';
            return (await state.stripePromise)!.redirectToCheckout({
              sessionId: response.id
            });
          })
          .catch(err => {
            isCheckout.value = false;
            console.log(err);
          })
      )
        .pipe(retry(3))
        .subscribe(result => {
          const resultData: any = result;
          isCheckout.value = false;
          if (resultData && resultData?.error) {
            invoiceState.value.state = 'error';
            invoiceState.value.message = resultData.error.message!;
          }
        });
    };
    // Handle Invoicing
    const isSendInvoice = ref(false);
    const sendInvoice = async () => {
      try {
        isSendInvoice.value = true;
        const invoice = await createInvoice({
          lineItems: selectedProduct.value.map(item => ({
            price: item.priceId,
            quantity: item.quantity
          })),
          customerId: user.value.stripeId
        });
        // eslint-disable-next-line no-unreachable
        if (invoice?.openInvoice?.hosted_invoice_url)
          window.location.href = invoice?.openInvoice?.hosted_invoice_url;
        window.location.href = invoice?.openInvoice?.hosted_invoice_url;
        invoiceState.value.state = 'success';
        invoiceState.value.state = 'success';
        setTimeout(() => {
          isSendInvoice.value = false;
        }, 2000);
      } catch (err) {
        invoiceState.value.state = 'error';
        invoiceState.value.message = err;
        isSendInvoice.value = false;
      }
      // handle invoice logic
    };
    return {
      sponsorName,
      purchaseOptions,
      checkout,
      selectedProduct,
      sendInvoice,
      invoiceState,
      user,
      isSendInvoice,
      isCheckout,
      isOrg,
      qt,
      purchaseOptionList,
      permissions,
      organizationPermissions
    };
  }
};
