var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('ValidationObserver',{scopedSlots:_vm._u([{key:"default",fn:function(ref){return [_c('div',{staticClass:"sponsor__container d-flex flex-column"},[(_vm.invoiceState.state === 'error')?_c('v-alert',{attrs:{"type":"error"}},[_vm._v(_vm._s(_vm.invoiceState.message))]):_vm._e(),_c('div',{staticClass:"sponsor__details d-flex flex-row justify-center align-start"},[_c('div',{staticClass:"sponsor__details-avatar"},[_c('Profile',{attrs:{"size":60}})],1),_c('div',{staticClass:"sponsor__details-namecard d-flex flex-column"},[_c('validation-provider',{attrs:{"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('v-text-field',{staticClass:"sponsor__details-name font-weight-bold",attrs:{"error-messages":errors,"height":"60","outlined":"","rounded":"","label":"Sponsor","hint":"Choose your public display name","placeholder":((_vm.user.firstName) + " " + (_vm.user.lastName))},model:{value:(_vm.sponsorName),callback:function ($$v) {_vm.sponsorName=$$v},expression:"sponsorName"}})]}}],null,true)})],1),_c('div',{staticClass:"sponsor__pay-card"},[_c('v-btn',{attrs:{"x-large":"","rounded":"","outlined":"","text":"","disabled":!_vm.selectedProduct.length ||
            _vm.isCheckout ||
            !_vm.permissions.includes('can_buy_token') ||
            (_vm.isOrg && !_vm.organizationPermissions.includes('can_buy_token')),"loading":_vm.isCheckout},on:{"click":_vm.checkout}},[_vm._v("Pay by Card")])],1),_c('div',{staticClass:"sponsor__pay-card"},[_c('v-btn',{attrs:{"x-large":"","rounded":"","outlined":"","text":"","disabled":!_vm.selectedProduct.length ||
            _vm.isSendInvoice ||
            !_vm.permissions.includes('can_buy_token') ||
            (_vm.isOrg && !_vm.organizationPermissions.includes('can_buy_token')),"loading":_vm.isSendInvoice},on:{"click":_vm.sendInvoice}},[_vm._v("Pay by Invoice")])],1)]),(_vm.purchaseOptionList.length)?_c('div',{staticClass:"mx-16"},_vm._l((_vm.purchaseOptionList),function(option){return _c('v-card',{key:option.title,staticClass:"mb-3",attrs:{"elevation":"0","color":option.color,"dark":""}},[_c('v-card-title',{staticClass:"landing__menu-title"},[_c('div',{staticClass:"sponsor__option-title white--text font-weight-bold"},[_vm._v(" "+_vm._s(option.title)+" ")]),_c('div',{staticClass:"sponsor__option-unit font-weight-bold"},[(option.unitLabel)?_c('v-chip',{attrs:{"color":"white","outlined":""}},[_vm._v(" "+_vm._s(option.unitLabel)+" ")]):_vm._e()],1),_c('div',{staticClass:"sponsor__option-price white--text mr-auto"},[_vm._v("$"+_vm._s(option.price / 100))]),_c('v-text-field',{staticClass:"sponsor__quantity-check",attrs:{"min":"0","outlined":"","x-large":"","label":"Quantity","type":"number","hide-details":""},model:{value:(option.quantity),callback:function ($$v) {_vm.$set(option, "quantity", $$v)},expression:"option.quantity"}})],1),_c('v-divider'),(option.desc)?_c('v-card-subtitle',[_vm._v(_vm._s(option.desc))]):_vm._e()],1)}),1):_c('div',{staticClass:"d-flex align-center justify-center"},[_c('v-progress-circular',{attrs:{"color":"black","size":"60","width":"4","indeterminate":""}})],1)],1)]}}])})}
var staticRenderFns = []

export { render, staticRenderFns }